<template>
    <b-card title="Delete Order">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Order No">
                        <b-form-input placeholder="Please Enter Order No"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-button variant="primary" class="mt-2 mr-2">Show Details</b-button>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="8">
                    <h4>Order Information</h4>
                    <b-row>
                    <b-col col="6">
                        <b-list-group>
                        <b-list-group-item><b>Order Number</b></b-list-group-item>
                        <b-list-group-item><b>Name</b></b-list-group-item>
                        <b-list-group-item><b>Address</b></b-list-group-item>
                        <b-list-group-item><b>Booking Date</b></b-list-group-item>
                        <b-list-group-item><b>Net Amount</b></b-list-group-item>
                        <b-list-group-item><b>Payment Mode</b></b-list-group-item>
                    </b-list-group>
                    </b-col>
                    
                    <b-col col="6">
                        <b-list-group>
                        <b-list-group-item>P2345</b-list-group-item>
                        <b-list-group-item>Muskan</b-list-group-item>
                        <b-list-group-item>Shahpura</b-list-group-item>
                        <b-list-group-item>09-10-2022</b-list-group-item>
                        <b-list-group-item>1200</b-list-group-item>
                        <b-list-group-item>Cash</b-list-group-item>
                    </b-list-group>
                    </b-col>
                </b-row>

                </b-col>
                <b-col md="4" class="text-center">
                    <b-button variant="danger" style="margin-bottom:-240px;" class="mt-1 mr-1">Delete This Booking</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BForm, BFormGroup, BFormInput, BButton, BListGroup, BListGroupItem
} from 'bootstrap-vue'

export default {
    components: {
        BCard, BCol, BRow, BForm, BFormGroup, BFormInput, BButton, BListGroup, BListGroupItem
    },
}
</script>